import QuestionAnswer from 'components/core/QuestionAnswer'
import { FaqCollectionModel } from 'models/body/FaqCollectionModel'
import React, { FunctionComponent } from 'react'
type Props = {
  data: FaqCollectionModel
}

const FaqCollection: FunctionComponent<Props> = (props) => {
  return (
    <div className="container mt-20 flex flex-col gap-y-8">
      {props.data.title && <h3 className="text-2xl font-semibold text-center">{props.data.title}</h3>}
      <div className="flex flex-col gap-y-4">
        {props.data.items.map((item, index) => (
          <div key={index} className="w-full flex flex-col justify-center py-2 px-4 border border-gray-200 rounded-md shadow-sm">
            <QuestionAnswer key={index} data={item} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaqCollection
