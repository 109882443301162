import ResponsiveImageForSlider from 'components/core/ResponsiveImageForSlider'
import { SlideshowModel } from 'models/hero/SlideshowModel'
import React, { Fragment, FunctionComponent } from 'react'
import Slider, { Settings } from 'react-slick'

type Props = {
  slide: SlideshowModel
}

const SlidesRenderer: FunctionComponent<Props> = (props) => {
  const sliderSettings: Settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    swipeToSlide: false,
    fade: true,
    cssEase: 'linear',
  }
  const images = props.slide.images
  const mockup = props.slide.mockup

  if (mockup == 'Desktop') {
    return (
      <Slider {...sliderSettings} className="desktop-slider invisible md:visible">
        {images.data.map((image, index) => (
          <div key={index} className="slider-item">
            <ResponsiveImageForSlider data={image} />
          </div>
        ))}
      </Slider>
    )
  }

  if (mockup === 'Laptop') {
    return (
      <Slider {...sliderSettings} className="laptop-slider invisible md:visible">
        {images.data.map((image, index) => (
          <div key={index} className="slider-item">
            <ResponsiveImageForSlider data={image} />
          </div>
        ))}
      </Slider>
    )
  }

  if (mockup === 'Phone') {
    return (
      <Slider {...sliderSettings} className="phone-slider">
        {images.data.map((image, index) => (
          <div key={index} className="slider-item">
            <ResponsiveImageForSlider data={image} />
          </div>
        ))}
      </Slider>
    )
  }

  if (mockup === 'Tablet') {
    return (
      <Slider {...sliderSettings} className="tablet-slider">
        {images.data.map((image, index) => (
          <div key={index} className="slider-item">
            <ResponsiveImageForSlider data={image} />
          </div>
        ))}
      </Slider>
    )
  }

  return null
}

export default SlidesRenderer