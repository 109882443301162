import React, { Fragment } from 'react'
import { FunctionComponent } from 'react'
import { motion } from 'framer-motion'
import { HomeHeroModel } from 'models/hero/HomeHeroModel'
import Button from 'components/core/Button'
import ResponsiveImage from 'components/core/ResponsiveImage'
import Slider from 'react-slick'
import DeviceSlideshows from './DeviceSlideshows'

type Props = {
  data: HomeHeroModel
}

const HomeHero: FunctionComponent<Props> = (props) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const data = props.data
  return (
    <Fragment>
        <div className="container">
          <motion.div initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ type: 'tween', duration: 1 }}>
            <h1 className="lg:text-[50px] mt-5 md:mt-0 md:text-[38px] md:max-w-[960px] md:mx-auto font-lato text-[30px] font-bold leading-[1.2em] text-center text-white mb-4">{data.title}</h1>
            <p className="text-center text-[#ffffffb3] text-[20px] leading-[1.5em] md:max-w-[800px] md:mx-auto">{data.subtitle}</p>
          </motion.div>

          <div className="flex flex-col md:flex-row justify-center items-center gap-3">
            {data.buttons.map((button, index) => (
              <motion.div key={index} initial={{ scale: 0.25, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ type: 'tween', duration: 1, delay: 0.1 }}>
                <Button data={button} />
              </motion.div>
            ))}
          </div>
          <motion.p initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ type: 'tween', duration: 1, delay: 1 }} className="mt-8">
            {data.buttonSubText}
          </motion.p>
          {data.slideshows && <DeviceSlideshows slideshows={data.slideshows} />}
          <h2 className="lg:text-[30px] md:max-w-[820px] md:mx-auto md:text-[25px] text-xl text-center leading-[1.3em] pt-10 pb-14 text-white">{data.appStoreTitle}</h2>
          <div className="app-list">
            <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 gap-5 max-w-[970px] mx-auto">
              {data.appStoreButtons.map((button, index) => (
                <Button extraClassNames="w-48" key={index} data={button} />
              ))}
            </div>
          </div>
          <Slider {...sliderSettings} className="banner-bottom-slider lg:max-w-[760px] max-w-[76%] mt-8 mx-auto">
            {data.customerReviews.map((review, index) => (
              <div key={index} className="slider-item text-center text-white text-[22px] leading-[1.2em]">
                <p>
                  <em>“{review.title}”</em>
                </p>
                <p className="text-center text-2xl font-extrabold text-white">
                  <ResponsiveImage data={review.image} extraClassNames="max-h-[50px]" />
                </p>
              </div>
            ))}
          </Slider>
        </div>
    </Fragment>
  )
}

export default HomeHero
