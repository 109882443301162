import Button from 'components/core/Button'
import ResponsiveImage from 'components/core/ResponsiveImage'
import { ProductDownloadModel } from 'models/body/ProductDownloadModel'
import React, { Fragment, FunctionComponent } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'

type Props = {
  data: ProductDownloadModel
}

const ProductDownload: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <section style={props.data.dynamicStyles} className={`py-[30px] md:py-[60px] text-center ${GetThemeClasses(props.data.theme)}`}>
        <div className="container flex flex-col items-center">
          {props.data.title && <h2 className="lg:text-[40px] lg:leading-[1.2em] text-white text-[30px] leading-[1.2em] font-bold pb-10">{props.data.title}</h2>}
          {props.data.subtitle && <h3 className="lg:text-[32px] lg:leading-[1.2em] text-white text-[28px] leading-[1.2em] font-semibold pb-10">{props.data.title}</h3>}
          {props.data.featureImage.data && <ResponsiveImage data={props.data.featureImage} />}
          <div className="flex flex-wrap justify-center gap-x-10">
            {props.data.downloadButtons.map((group, index) => (
              <div key={index} className="flex flex-col items-center">
                <div className="flex items-center gap-x-3">
                  {group.buttons.map((button, index) => {
                    return <Button key={index} data={button} />
                  })}
                </div>
                <h6 className="text-white text-[14px] font-normal mt-3 leading-5">{group.subText}</h6>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default ProductDownload
