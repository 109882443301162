import { ImageSizeType } from 'common/enums/ImageSizeType'
import ResponsiveImage from 'components/core/ResponsiveImage'
import TextBlock from 'components/core/TextBlock'
import { ImagePositionType } from 'models/enums/ImagePositionType'
import { ThemeType } from 'models/enums/ThemeType'
import React, { Fragment, FunctionComponent } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'
import Fade from 'react-reveal/Fade'
import { TextBlocksWithAnimatedLogosModel } from 'models/body/TextBlocksWithAnimatedLogosModel'
import AnimatedLogos from 'components/core/AnimatedLogos'

type Props = {
  data: TextBlocksWithAnimatedLogosModel
}

const TextBlocksWithAnimatedLogos: FunctionComponent<Props> = (props) => {
  const position = props.data.imagePosition
  return (
    <Fragment>
      <section style={props.data.dynamicStyles} className={`py-12 ${GetThemeClasses(props.data.theme)} ${props.data.styleClassNames}`}>
        <div className="container">
          <h3
            className={`lg:text-[40px] md:text-[30px] text-[25px] font-lato font-bold leading-[1.3em] lg:w-1/2 mx-auto text-center ${
              props.data.theme === ThemeType.Light ? 'text-black' : 'text-white'
            }`}
          >
            {props.data.title}
          </h3>
          <div
            className={`mx-auto pt-16 flex flex-col items-center ${position === ImagePositionType.Right && 'lg:!flex-row-reverse'} ${
              position === ImagePositionType.Left && 'lg:!flex-row'
            }`}
          >
            <div className="lg:w-1/2 xl:w-5/12 lg:mb-0 w-full mb-10 text-center flex justify-center lg:block">
              <AnimatedLogos data={props.data.logos} />
            </div>
            <div className={`w-full lg:w-1/2 xl:w-7/12 lg:pr-20 lg:text-left md:pr-16 text-center ${position === ImagePositionType.Left && 'lg:ml-8'}`}>
              {props.data.textBlocks.map((block, index) => {
                return <TextBlock imagePosition={position} key={index} data={block} theme={props.data.theme} />
              })}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default TextBlocksWithAnimatedLogos
