import { SlideshowModel } from 'models/hero/SlideshowModel'
import React, { Fragment, FunctionComponent } from 'react'
import SlidesRenderer from './SlidesRenderer'

type Props = {
  slideshows?: SlideshowModel[]
}

const DeviceSlideshows: FunctionComponent<Props> = (props) => {
  if (!props.slideshows) {
    return null
  }
  return (
    <Fragment>
      <div className="home-slider mt-3">
        {props.slideshows.map((slideshow, index) => (
          <SlidesRenderer key={index} slide={slideshow} />
        ))}
      </div>
    </Fragment>
  )
}

export default DeviceSlideshows
