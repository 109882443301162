import { ThemeType } from 'models/enums/ThemeType'

export function GetThemeClasses(theme: ThemeType): string {
  if (theme === ThemeType.Dark) {
    return 'bg-[#141415] text-white'
  }
  if (theme === ThemeType.Light) {
    return 'bg-white text-[#262626]'
  }
  if (theme === ThemeType.Accent) {
    return 'bg-gradient-to-r from-[#0f0c2b] to-[#23134b] text-white'
  }

  return 'text-black'
}
