import Button from 'components/core/Button'
import CdnImage from 'components/core/CdnImage'
import { BannerModel } from 'models/body/BannerModel'
import React, { Fragment, FunctionComponent } from 'react'
import Fade from 'react-reveal/Fade'

type Props = {
  data: BannerModel
}

const Banner: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <div className={`orange-box ${props.data.styleClassNames}`}>
        <div className="container">
          <div style={props.data.dynamicStyles} className="md:pl-[90px] md:pr-[45px] relative bg-gradient-to-r from-[#f95401] to-[#f8c131] rounded-2xl py-[35px] px-4">
            <CdnImage className="absolute left-0 top-1/2 -translate-y-2/4" alt="Banner Logo" fileName="logo-half.png" />
            <div className="flex flex-wrap items-center relative z-10 text-center">
              <Fade right>
                <div className="lg:w-2/3 lg:text-left md:text-[20px] w-full text-[#fdd8cc] text-base">
                  <h3 className="xl:text-[28px] md:text-[25px] font-gothambold text-bold text-xl text-white leading-[1.2em] font-bold">{props.data.title}</h3>
                  <p className="pb-0">{props.data.subtitle}</p>
                </div>
              </Fade>
              <div className="lg:w-1/3 lg:text-right w-full text-center mt-4 lg:mt-0">
                {props.data.buttons.map((button, index) => (
                  <Fade key={index} left>
                    <Button data={button} key={index} />
                  </Fade>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Banner
