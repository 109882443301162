import ProductVariant from 'components/core/ProductVariant'
import { ProductFeaturesSectionModel } from 'models/body/ProductFeaturesSectionModel'
import React, { Fragment, FunctionComponent } from 'react'

type Props = {
  data: ProductFeaturesSectionModel
}

const ProductFeaturesSection: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <section className={`${props.data.className} product-features-wrap text-center mb-12 md:mb-20`}>
        <div className='container'>
          <div className='md:flex mt-10'>
            {props.data.productVariants.map((variant, index) => (
              <ProductVariant key={index} keyVal={index} data={variant}/>
            ))}            
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default ProductFeaturesSection
