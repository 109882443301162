import React, { FunctionComponent } from 'react'
import Markdown from 'markdown-to-jsx'
import ResponsiveImage from './ResponsiveImage'
import { ProductVariantModel } from 'models/core/ProductVariantModel'
import Fade from 'react-reveal/Fade'

type Props = {
  data: ProductVariantModel
  keyVal: number
}

const ProductVariant: FunctionComponent<Props> = (props) => {
  return (
    <Fade left={props.keyVal === 0} right={props.keyVal === 1}>
      <div className={`w-full md:w-1/2 product-features-item`}>
        {props.data.featureImage && 
          <div className="text-center mb-5">
            <ResponsiveImage data={props.data.featureImage} />
          </div>
        }
        <h3 className="font-lato font-bold text-2xl md:text-3xl text-black mb-2.5 p-0">{props.data.title}</h3>
        {props.data.isPremium ? (
          <a href='#download-app' className="cursor-pointer bg-gradient-to-r from-[#542FD0] to-[#D133F6] font-lato font-normal text-sm text-white rounded uppercase inline-block py-[3px] px-[18px]">Premium</a>
          ): (
            <a href='#download-app' className="cursor-pointer font-lato font-bold text-sm text-white bg-[#645BF0] rounded uppercase inline-block py-[3px] px-[18px]">Free</a>
        )}
        <div className='product-variant-subtitle text-[#6652AD] text-lg font-bold mt-[18px] px-8 product-features-intro tracking-tight'>
          {props.data.subTitle && 
            <Markdown>{props.data.subTitle}</Markdown>
          }
        </div>
        <div className='product-features-item-content text-left mt-[25px] space-y-5'>
          <Markdown>{props.data.description}</Markdown>
        </div>
      </div>
    </Fade>
  )
}

export default ProductVariant
