import Button from 'components/core/Button'
import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { TextWithCtaButtonsModel } from 'models/body/TextWithCtaButtonsModel'
import { ThemeType } from 'models/enums/ThemeType'
import React, { Fragment, FunctionComponent } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'
import Fade from 'react-reveal/Fade'

type Props = {
  data: TextWithCtaButtonsModel
}

const TextWithCtaButtons: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <section style={props.data.dynamicStyles} className={`py-10 text-center overflow-y-hidden ${GetThemeClasses(props.data.theme)} ${props.data.styleClassNames}`}>
        <div className="container">
          <Fade left>
            <Fragment>
              <h2
                className={`lg:text-[38px] md:text-[30px] text-[25px] text-center leading-[1.2em] font-bold ${
                  props.data.theme === ThemeType.Dark || props.data.theme === ThemeType.Accent ? 'text-white' : 'text-black'
                }`}
              >
                {props.data.title}
              </h2>
              {props.data.subtitle && (
                <h2
                  className={`lg:text-[28px] md:text-[25px] text-[20px] text-center leading-[1.2em] font-extralight ${
                    props.data.theme === ThemeType.Dark || props.data.theme === ThemeType.Accent ? 'text-white' : 'text-black'
                  }`}
                >
                  {props.data.subtitle}
                </h2>
              )}
              <div className="text-lg text-center">{props.data.content && <Markdown className="text-[20px] md:w-10/12 mx-auto">{props.data.content}</Markdown>}</div>
            </Fragment>
          </Fade>
          {props.data.featureImage?.data && (
            <div className="w-full flex justify-center">
              <Fade right>
                <ResponsiveImage data={props.data.featureImage} />
              </Fade>
            </div>
          )}
          <div className="mt-6 pb-10 flex justify-center items-center gap-x-5">
            {props.data.button.map((button, index) => {
              return (
                <Fade key={index} right>
                  <Button key={index} data={button} extraClassNames={`w-40 lg:w-48`} />
                </Fade>
              )
            })}
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default TextWithCtaButtons
