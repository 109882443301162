import { motion } from 'framer-motion'
import { SingleTextHeroModel } from 'models/hero/SingleTextHeroModel'
import React, { Fragment, FunctionComponent } from 'react'

type Props = {
  data: SingleTextHeroModel
  className?: string
}

const SingleTextHero: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <motion.div initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ type: 'tween', duration: 1 }} className="container">
          <h1 className="lg:text-[50px] mt-5 md:mt-0 md:text-[35px] text-white font-lato text-[30px] font-bold leading-[1.2em] mb-6 lg:w-2/3 mx-auto">{props.data.title}</h1>
          <p className="text-[#c2c1ca] lg:w-2/3 mx-auto pb-0">{props.data.subtitle}</p>
        </motion.div>
    </Fragment>
  )
}

export default SingleTextHero
