import Email from 'components/core/Email'
import ResponsiveImage from 'components/core/ResponsiveImage'
import { EmailCollectionModel } from 'models/body/EmailCollectionModel'
import React, { Fragment, FunctionComponent } from 'react'

type Props = {
  data: EmailCollectionModel
}

const EmailCollection: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <div className="container">
        <h3 className="lg:text-3xl text-[#262626] flex items-center pt-7 pl-3 text-[20px]">
          <ResponsiveImage data={props.data.iconBesideTitle} extraClassNames="mr-2" /> Email us:
        </h3>
        <div className="mt-10 w-full md:grid grid-flow-row grid-cols-2 lg:grid-cols-3 gap-6">
          {props.data.emails.map((email, index) => {
            return <Email key={index} email={email} />
          })}
        </div>
      </div>
    </Fragment>
  )
}

export default EmailCollection
