import React, { PropsWithChildren, useEffect } from 'react'

type AnyProps = {
  data?: any
  componentClassNames?: any
}

function AnyHeroWrapper(props: PropsWithChildren<AnyProps>) {

  useEffect(() => {
    function init() {
      const anyHero = document.getElementById('any-hero');
      const headerContainer = document.getElementById('header-container');

      setTimeout(()=> {
        // @ts-ignore
        anyHero.style.paddingTop = `${headerContainer?.clientHeight}px`;
      })
    }

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", init);
    } else {
      init();
    }
  }, []);

  return (
    <div id="any-hero" className={`${props.componentClassNames !== undefined ? props.componentClassNames : ''} ${props.data?.styleClassNames !== undefined ? props.data?.styleClassNames : ''}`}>
      {props.children}
    </div>
  )
}

export default AnyHeroWrapper