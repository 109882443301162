import { AnimatePresence, motion } from 'framer-motion'
import Markdown from 'markdown-to-jsx'
import { QuestionAnswerModel } from 'models/core/QuestionAnswerModel'
import React, { Fragment, FunctionComponent, useState } from 'react'
import { Disclosure, Transition } from '@headlessui/react'

type Props = {
  data: QuestionAnswerModel
}

const QuestionAnswer: FunctionComponent<Props> = (props) => {
  const [opened, setOpened] = useState(false)
  return (
    <Fragment>
      <Disclosure>
        <Disclosure.Button className="w-full flex items-center justify-between" onClick={() => setOpened(!opened)}>
          <h2 style={{ fontSize: '1.5rem' }} className="text-left w-[75%] text-lg lg:text-xl xl:text-2xl">
            {props.data.question}
          </h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-6 w-6 text-gray-500 transition-all duration-300 ${opened ? 'rotate-180' : ''}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
          </svg>
        </Disclosure.Button>
        <Transition
          show={opened}
          enter="transition-all duration-300"
          enterFrom="-translate-y-4 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition-all duration-300"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="-translate-y-4 opacity-0"
        >
          <Disclosure.Panel>
            <div className="faq-prose mt-2">
              <Markdown>{props.data.answer}</Markdown>
            </div>
          </Disclosure.Panel>
        </Transition>
      </Disclosure>
    </Fragment>
  )
}

export default QuestionAnswer
