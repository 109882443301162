import ResponsiveImage from 'components/core/ResponsiveImage'
import { motion } from 'framer-motion'
import { HeroWithIconGroupModel } from 'models/hero/HeroWithIconGroupModel'
import React, { Fragment, FunctionComponent } from 'react'

type Props = {
  data: HeroWithIconGroupModel
}

const HeroWithIconGroup: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <motion.div initial={{ scale: 0.25, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ type: 'tween', duration: 1 }} className="container">
        <p className="pb-0 mt-5 md:mt-0">{props.data.subText}</p>
        <h1 className="lg:text-[50px] md:text-[35px] font-lato text-[30px] font-bold leading-[1em] text-white mb-6">{props.data.title}</h1>
        <p className="text-[#c2c1ca]">{props.data.subtitle}</p>
        <ul className="partner-logo pt-3">
          {props.data.icons.map((icon, index) => (
            <li key={index} className={`sm:w-auto w-[16%] inline-block px-[5px] ${icon.styleClassNames}`}>
              <ResponsiveImage data={icon.image} />
            </li>
          ))}
        </ul>
      </motion.div>
    </Fragment>
  )
}

export default HeroWithIconGroup
