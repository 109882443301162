import Banner from 'components/body/Banner'
import DownloadsSection from 'components/body/DownloadsSection'
import EmailCollection from 'components/body/EmailCollection'
import FaqCollection from 'components/body/FaqCollection'
import HorizontalLine from 'components/body/HorizontalLine'
import IconsWithRichTextAndImage from 'components/body/IconsWithRichTextAndImage'
import MultipleFeaturesGroup from 'components/body/MultipleFeaturesGroup'
import ProductDownload from 'components/body/ProductDownload'
import ProductFeaturesSection from 'components/body/ProductFeaturesSection'
import TextBlocksWithAnimatedLogos from 'components/body/TextBlocksWithAnimatedLogos'
import TextBlocksWithFeatureImage from 'components/body/TextBlocksWithFeatureImage'
import TextWithCtaButtons from 'components/body/TextWithCtaButtons'
import TextWithFeatureImage from 'components/body/TextWithFeatureImage'
import TextWithIcons from 'components/body/TextWithIcons'
import TitleAndRichText from 'components/body/TitleAndRichText'
import TopPoster from 'components/body/TopPoster'
import React, { Fragment, FunctionComponent, ReactElement } from 'react'
// import { createPortal } from 'react-dom'

type Props = {
  data: any
}

type AvailableComponents = { [key: string]: ReactElement }

const BodyRenderer: FunctionComponent<Props> = (props) => {
  const componentEntries: AvailableComponents = {
    'section.text-with-icons': <TextWithIcons data={props.data} />,
    'section.text-blocks-with-feature-image': <TextBlocksWithFeatureImage data={props.data} />,
    'section.text-with-cta-button': <TextWithCtaButtons data={props.data} />,
    'section.banner': <Banner data={props.data} />,
    'section.text-with-feature-image': <TextWithFeatureImage data={props.data} />,
    'section.horizontal-line': <HorizontalLine data={props.data} />,
    'section.multiple-features-group': <MultipleFeaturesGroup data={props.data} />,
    'section.title-and-rich-text': <TitleAndRichText data={props.data} />,
    'section.icons-with-rich-text-and-image': <IconsWithRichTextAndImage data={props.data} />,
    'section.email-collection': <EmailCollection data={props.data} />,
    'section.faq-collection': <FaqCollection data={props.data} />,
    'section.product-download': <ProductDownload data={props.data} />,
    'section.downloads-section': <DownloadsSection data={props.data} />,
    'section.product-features-section': <ProductFeaturesSection data={props.data} />,
    'section.text-blocks-with-animated-logos': <TextBlocksWithAnimatedLogos data={props.data} />,
    'section.top-poster': <TopPoster data={props.data} />,
  }

  return <Fragment>{componentEntries[props.data.component]}</Fragment>
}

export default BodyRenderer
