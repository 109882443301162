import { ImageSizeType } from 'common/enums/ImageSizeType'
import { AnimatedLogosModel } from 'models/body/AnimatedLogosModel'
import React, { Fragment, FunctionComponent } from 'react'
import ResponsiveImageForSlider from './ResponsiveImageForSlider'

type Props = {
  data: AnimatedLogosModel
}

const AnimatedLogos: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <div className="brands-wrappers bg-[#232324] rounded-[20px] px-[35px] pb-6 mt-[60px] w-10/12 lg:max-w-[400px] mx-auto">
        <ul className="brand-logos bg-white p-6 rounded-[20px] ml-0 sm:-ml-[60px] -top-[60px] -mb-[35px] relative">
          {props.data.brandLogos.data.map((brandLogo, index) => (
            <li key={index} className="brand-style grid place-items-center">
              <ResponsiveImageForSlider isThumbnail imageSize={ImageSizeType.Thumbnail} data={brandLogo} />
            </li>
          ))}
        </ul>
        <ul className="brand-icons flex justify-between items-center">
          {props.data.icons.data.map((icon, index) => (
            <li key={index} className="inline-block w-[90%]">
              <ResponsiveImageForSlider isThumbnail imageSize={ImageSizeType.Thumbnail} data={icon} />
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  )
}

export default AnimatedLogos
