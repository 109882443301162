import React, { Fragment, FunctionComponent } from 'react'
import { EmailModel } from '../../models/core/EmailModel'

type Props = {
  email: EmailModel
}

const Email: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <div className="border border-solid min-h-full border-[#f0f0f0] px-[25px] py-[30px] group transition-all duration-300 hover:shadow-[6px_6px_18px_0_#f0f0f0]">
        <h3 className="text-xl text-[#262626] pb-0">{props.email.title}</h3>
        <a
          className="bg-icon-right-gray bg-no-repeat text-lg font-normal text-[#6b6b6b] bg-[96%_center] block transition-all duration-300 group-hover:bg-[right_center] group-hover:text-[#f06520] group-hover:bg-icon-right"
          href={`mailto:${props.email.address}`}
        >
          {props.email.address}
        </a>
      </div>
    </Fragment>
  )
}

export default Email
