import ResponsiveImage from 'components/core/ResponsiveImage'
import { motion } from 'framer-motion'
import { HeroWithFeatureImageModel } from 'models/hero/HeroWithFeatureImageModel'
import React, { Fragment, FunctionComponent } from 'react'

type Props = {
  data: HeroWithFeatureImageModel
}

const HeroWithFeatureImage: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <div className="container">
        <motion.div initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ type: 'tween', duration: 1 }}>
          <h1 className="lg:text-[50px] mt-5 md:mt-0 md:text-[35px] text-white font-lato text-[30px] font-bold leading-[1.33em] mb-6">{props.data.title}</h1>
          <p className="lg:w-2/3 mx-auto">{props.data.subtitle}</p>
        </motion.div>
        <div className="flex justify-center w-full">{props.data.featureImage.data && <ResponsiveImage data={props.data.featureImage} />}</div>
      </div>
    </Fragment>
  )
}

export default HeroWithFeatureImage
