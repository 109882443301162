import React, { Fragment, FunctionComponent } from 'react'
import Markdown from 'markdown-to-jsx'
import { TextBlockModel } from 'models/core/TextBlockModel'
import { ThemeType } from 'models/enums/ThemeType'
import CdnImage from './CdnImage'
import Fade from 'react-reveal/Fade'
import { ImagePositionType } from 'models/enums/ImagePositionType'

type Props = {
  data: TextBlockModel
  theme: ThemeType
  imagePosition: ImagePositionType
}

const TextBlock: FunctionComponent<Props> = (props) => {
  const theme = props.theme
  const decideTextColor = (): string => {
    if (props.theme === ThemeType.Dark) {
      return 'text-[#a6a7a7]'
    }
    if (props.theme === ThemeType.Light) {
      return 'text-[rgb(107,107,107)]'
    }

    if (props.theme === ThemeType.Accent) {
      return 'text-[rgb(201,201,201)]'
    }

    return 'text-[rgb(107,107,107)]'
  }
  return (
    <Fragment>
      <Fade left={props.imagePosition === ImagePositionType.Right ? true : undefined} right={props.imagePosition === ImagePositionType.Left ? true : undefined}>
        <div className={`mb-6 leading-[28px]`}>
          <h4
            className={`font-lato text-[25px] leading-[1.3em] mb-[10px] font-semibold ${
              props.theme === ThemeType.Dark || props.theme === ThemeType.Accent ? 'text-white' : 'text-black'
            }`}
          >
            {props.data.title}
          </h4>
          {props.data.content && (
            <Markdown style={props.data.dynamicStyles} className={`text-[18px] font-light font-lato ${decideTextColor()}`}>
              {props.data.content}
            </Markdown>
          )}
          {props.data.link && props.data.linkDisplay && (
            <a className="text-[#f06725] text-[18px] leading-[28px] block mt-4" href={props.data.link}>
              {props.data.linkDisplay}
              <CdnImage width={16} height={16} className="inline-block ml-2" fileName="more.png" alt="Arrow" />
            </a>
          )}
        </div>
      </Fade>
    </Fragment>
  )
}

export default TextBlock
