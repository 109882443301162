import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { TextWithFeatureImageModel } from 'models/body/TextWithFeatureImageModel'
import { ImagePositionType } from 'models/enums/ImagePositionType'
import { ThemeType } from 'models/enums/ThemeType'
import React, { Fragment, FunctionComponent, useEffect, useRef } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'
import Fade from 'react-reveal/Fade'

type Props = {
  data: TextWithFeatureImageModel
}

const TextWithFeatureImage: FunctionComponent<Props> = (props) => {
  const proseRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    proseRef.current?.querySelectorAll('strong').forEach((el) => {
      if (props.data.theme === ThemeType.Dark) {
        el.classList.add('text-white')
      }
    })
    return () => {}
  }, [])
  return (
    <Fragment>
      <section style={props.data.dynamicStyles} className={`py-10 text-center ${GetThemeClasses(props.data.theme)} ${props.data.styleClassNames}`}>
        <div className="container">
          {props.data.imagePosition === ImagePositionType.Top ? (
            <Fade left>
              <div className="mt-6 flex w-full justify-center">
                <ResponsiveImage data={props.data.featureImage} />
              </div>
            </Fade>
          ) : null}
          <div className="md:w-2/3 lg:w-1/2 mx-auto mt-6">
            <h3 className={`md:text-[40px] text-center font-lato font-medium text-4xl leading-[1.2em] mb-3 ${props.data.theme === ThemeType.Light ? 'text-black' : 'text-white'}`}>
              {props.data.title}
            </h3>
          </div>
          <div className="md:text-2xl text-lg leading-[1.5em] font-normal">
            <p className="md:w-2/3 mx-auto">{props.data.subtitle}</p>
            {props.data.imagePosition !== ImagePositionType.Top && props.data.imagePosition !== ImagePositionType.Bottom && (
              <div className="mt-6 flex w-full justify-center">
                <ResponsiveImage data={props.data.featureImage} />
              </div>
            )}
          </div>
          <Fade left={props.data.imagePosition === ImagePositionType.Right} right={props.data.imagePosition === ImagePositionType.Left}>
            <div ref={proseRef} className={`md:pr-[154px] md:pl-[154px] md:text-2xl text-lg leading-[1.3em] ${props.data.theme === ThemeType.Dark && 'text-[#a6a7a7]'}`}>
              <Markdown>{props.data.content}</Markdown>
            </div>
          </Fade>
          {props.data.imagePosition === ImagePositionType.Bottom ? (
            <Fade left>
              <div className="mt-6 flex w-full justify-center">
                <ResponsiveImage data={props.data.featureImage} />
              </div>
            </Fade>
          ) : null}
        </div>
      </section>
    </Fragment>
  )
}

export default TextWithFeatureImage
