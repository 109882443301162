import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { MultipleFeaturesGroupModel } from 'models/body/MultipleFeaturesGroupModel'
import { ThemeType } from 'models/enums/ThemeType'
import React, { FunctionComponent } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'

type Props = {
  data: MultipleFeaturesGroupModel
}

const MultipleFeaturesGroup: FunctionComponent<Props> = (props) => {
  return (
    <div className={`py-28 ${GetThemeClasses(props.data.theme)} ${props.data.styleClassNames}`}>
      <div className="container lg:grid grid-flow-row grid-cols-2 gap-8">
        {props.data.features.map((feature, index) => {
          return (
            <div key={index} className="w-full text-lg leading-[1.66em] font-lato font-normal">
              {feature.image.data && <ResponsiveImage data={feature.image} extraClassNames="w-20" />}
              <h3 className={`md:text-3xl text-xl leading-[1.33em] font-bold pb-5 mt-8 ${props.data.theme === ThemeType.Light ? 'text-black' : 'text-white'}`}>{feature.title}</h3>
              <p
                className={`${props.data.theme === ThemeType.Dark && 'text-[rgb(173,174,174)]'} ${
                  props.data.theme === ThemeType.Light ? 'text-[rgb(107,107,107)]' : 'text-[rgb(173,174,174)]'
                }`}
              >
                <Markdown>{feature.subtitle}</Markdown>
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MultipleFeaturesGroup
