import { HorizontalLineModel } from 'models/body/HorizontalLineModel'
import { ThemeType } from 'models/enums/ThemeType'
import React, { FunctionComponent } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'

type Props = {
  data: HorizontalLineModel
}

const HorizontalLine: FunctionComponent<Props> = (props) => {
  const decideColor = () => {
    if (props.data.theme === ThemeType.Dark) {
      return 'bg-[rgb(42,43,43)]'
    }
    if (props.data.theme === ThemeType.Accent) {
      return 'bg-white'
    }
    return 'bg-[#f0f0f0]'
  }
  return (
    <div className={GetThemeClasses(props.data.theme)}>
      <span className={`w-11/12 md:w-10/12 mx-auto h-[2px] block ${decideColor()}`}></span>
    </div>
  )
}

export default HorizontalLine
