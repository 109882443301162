import HeroWithFeatureImage from 'components/hero/HeroWithFeatureImage'
import HeroWithIconGroup from 'components/hero/HeroWithIconGroup'
import HomeHero from 'components/hero/HomeHero'
import SingleTextHero from 'components/hero/SingleTextHero'
import React, { Fragment, FunctionComponent, ReactElement } from 'react'
import AnyHeroWrapper from './AnyHeroWrapper'

type Props = {
  data: any
}

type AvailableComponents = { [key: string]: { comp: ReactElement, classNames: string } }

const HeroRenderer: FunctionComponent<Props> = (props) => {
  const componentEntries: AvailableComponents = {
    'hero.home-hero': {
      comp: <HomeHero data={props.data} />,
      classNames: 'banner md:pb-36 bg-home-header bg-cover bg-no-repeat bg-bottom pb-[50px] text-center text-white text-base z-10 relative' 
    },
    'hero.single-text-hero': {
      comp: <SingleTextHero data={props.data} />,
      classNames: `inner-banner md:pb-[90px] md:text-2xl pb-[90px] bg-inner-banner bg-center bg-no-repeat bg-cover text-center z-10 relative text-lg text-white font-lato ${props.data.styleClassNames
        } ${props.data.className ?? ''}`
    },
    'hero.hero-with-feature-image': {
      comp: <HeroWithFeatureImage data={props.data} />,
      classNames: `inner-banner md:pb-[45px] pt-[50px] pb-[65px] bg-inner-banner bg-center bg-no-repeat bg-cover text-center z-10 relative text-2xl text-[#c2c1ca] font-lato ${props.data.styleClassNames}`
    },
    'hero.hero-with-icon-group': {
      comp: <HeroWithIconGroup data={props.data} />,
      classNames: `inner-banner md:pb-[115px] pt-[50px] pb-[100px] bg-inner-banner bg-center bg-no-repeat bg-cover text-center z-10 relative text-2xl text-white font-lato  ${props.data.styleClassNames}`
    },
  }
  
  return (
    <Fragment>
      <AnyHeroWrapper data={props.data} componentClassNames={componentEntries[props.data.component].classNames}>
        {componentEntries[props.data.component].comp}
      </AnyHeroWrapper>
    </Fragment>
  )
}

export default HeroRenderer
