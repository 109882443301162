import React, { FunctionComponent, useEffect } from 'react'
import { DownloadBlockModel } from 'models/core/DownloadBlockModel'
import ResponsiveImage from './ResponsiveImage'
import { DownloadButtonModel } from 'models/core/DownloadButtonModel'
import { UAParser } from 'ua-parser-js';

type Props = {
  data: DownloadBlockModel
  keyVal: number
}

const DownloadBlock: FunctionComponent<Props> = (props) => {
  const handleDownload = async (button: DownloadButtonModel) => {
    const link = document.createElement('a');
    link.href = button.link;

    if(button.isMacButton){
      // Mac Download button
      // @ts-ignore
      if (navigator.userAgentData != undefined) {
        // @ts-ignore
        let userArch = await navigator.userAgentData.getHighEntropyValues(['architecture'])
        link.href = userArch.architecture === 'arm' ? button.armLink : button.link 
      }
    }else{
      // Windows Download button
      var uap = new UAParser();
      let arch = uap.getCPU().architecture
      if (arch && arch.includes('86')){
        link.href = button.x86Link;
      }
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    pushDataLayer(button);
  }

  const pushDataLayer = (button: DownloadButtonModel) => {
    if(button.dataLayerJson){
      let jsonVal = JSON.parse(JSON.stringify(button.dataLayerJson))
      if (jsonVal.length){
        // @ts-ignore
        jsonVal.forEach(element => {
          // @ts-ignore
          window.dataLayer.push(element);
        });
      }else{
        // @ts-ignore
        window.dataLayer.push(jsonVal);
      }
    }
  }

  return (
    <div key={props.keyVal} className={`product-item ${props.data.buttons.length > 1 ? 'multiple-button': ''} font-lato text-[13px] leading-[15px] text-white text-center`}>
      <div className="bg-white block text-center rounded-[20px] pt-7 pb-6 px-8 md:p-2.5 lg:pt-7 lg:pb-6 lg:px-8 border-[3px] border-white mb-2.5
          transition-all duration-200 hover:border-[#FF8323] group">
        {props.data.icon && <ResponsiveImage extraClassNames='mb-7' data={props.data.icon} />}
        <h3 className="font-lato font-bold text-black mb-6 p-0">{props.data.title}</h3>
        {props.data.buttons.length > 1 ? (
          <>
            <span className="font-lato font-bold text-sm text-[#FF8323] uppercase border border-[#FF8323] inline-block rounded-[17px] 
                transition-all duration-200 py-1.5 px-5 group-hover:hidden">DOWNLOAD</span>
            <div className="w-full flex-col items-center hidden group-hover:flex">
              {props.data.buttons.map((button, index) => {
                return <a key={index} href={button.link} onClick={()=> pushDataLayer(button)} id={button.className} className={`${button.className} font-lato font-bold text-sm text-[#FF8323] uppercase border border-[#FF8323] rounded-[17px] 
                transition-all duration-200 py-1.5 px-5 hover:bg-[#FF8323] mb-1.5 min-w-[126px] hover:text-white hidden group-hover:inline-block`}>{button.label}</a>
              })}
            </div>
          </>
        ): (
            props.data.buttons.map((button, index) => {
              if (button.armLink || button.x86Link){
                return <a key={index} onClick={() => handleDownload(button)} id={button.className} className={`${button.className} download_link font-lato font-bold text-sm text-[#FF8323] uppercase border border-[#FF8323] inline-block rounded-[17px] 
                  transition-all duration-200 py-1.5 px-5 cursor-pointer hover:bg-[#FF8323] hover:text-white`}>{button.label}</a>
                }else{
                return <a key={index} href={button.link} onClick={() => pushDataLayer(button)} id={button.className} className={`${button.className} font-lato font-bold text-sm text-[#FF8323] uppercase border border-[#FF8323] inline-block rounded-[17px] 
                transition-all duration-200 py-1.5 px-5 hover:bg-[#FF8323] hover:text-white`}>{button.label}</a>
              }
            })
        )}
      </div>
      <div className='product-item-footer'>
        <span className="block">{props.data.subTitle}</span>
        {props.data.footerText}
      </div>
    </div>
  )
}

export default DownloadBlock
