import { ImageModel } from 'models/core/ImageModel'
import React, { FunctionComponent } from 'react'
import { GenerateImageLink } from 'utils/GenerateImageLink'
import { ImageSizeType } from 'common/enums/ImageSizeType'
import Env from 'Env'
import Image, { ImageLoader } from 'next/image'
import { GenerateImageLinkForSlider } from 'utils/GenerateImageLinkForSlider'

type Props = {
  data: ImageModel['data'] | undefined
  imageSize?: ImageSizeType
  extraClassNames?: string
  isThumbnail?: boolean
  maxHeight?: number
}

// Using custom srcSet

const ResponsiveImageForSlider: FunctionComponent<Props> = (props) => {
  const propsData = props.data
  const formats = propsData?.attributes.formats
  const height = propsData?.attributes.height!!
  const width = propsData?.attributes.width!!
  const link = props.data ? Env.StrapiUploadUrl + propsData?.attributes.hash + propsData?.attributes.ext : ''
  const calculateWidthAndHeight = () => {
    if (propsData?.attributes.ext === '.svg') {
      return { height, width }
    }
    return { height: Math.round(height / 3), width: Math.round(width / 3) }
  }
  const processed = calculateWidthAndHeight()
  const generateSrcSet = () => {
    if (propsData?.attributes.ext === '.svg') {
      return undefined
    }
    const images: string[] = []
    if (!formats) return undefined
    Object.keys(formats).forEach((res) => {
      const targetImg = formats[res]
      if (res !== 'thumbnail') {
        images.push(`${Env.StrapiUploadUrl}${targetImg.hash}${targetImg.ext} ${res}`)
      }
    })
    return images.join(',')
  }
  return (
    <img
      style={{ maxHeight: props.maxHeight || 'auto' }}
      className={props.extraClassNames ? props.extraClassNames : ''}
      height={props.isThumbnail ? height / 3 : processed.height}
      width={props.isThumbnail ? width / 3 : processed.width}
      src={link}
      loading="lazy"
      alt={propsData?.attributes.alternativeText || ''}
      sizes="70vw"
      srcSet={generateSrcSet()}
    />
  )
}

export default ResponsiveImageForSlider
