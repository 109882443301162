import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { IconsWithRichTextAndImageModel } from 'models/body/IconsWithRichTextAndImageModel'
import { ThemeType } from 'models/enums/ThemeType'
import React, { Fragment, FunctionComponent } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'

type Props = {
  data: IconsWithRichTextAndImageModel
}

const IconsWithRichTextAndImage: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <section className={`${GetThemeClasses(props.data.theme)}`}>
        <div className="container">
          <div className="md:flex-row-reverse md:flex items-center">
            <div className="lg:w-2/5 md:mb-0 w-full text-center">{props.data.featureImage?.data && <ResponsiveImage data={props.data.featureImage} />}</div>
            <div className="lg:w-3/5 md:pb-0 w-full pb-10 text-lg font-normal">
              <ul className="partner-logo pb-6">
                {props.data.icons.map((icon, index) => {
                  return (
                    <li key={index} className="sm:w-auto w-[16%] inline-block pr-[15px]">
                      <ResponsiveImage data={icon.image} />
                    </li>
                  )
                })}
              </ul>
              <h3 className={`md:text-[30px] font-lato font-bold text-[20px] leading-[1.33em] pb-5 ${props.data.theme === ThemeType.Light ? 'text-black' : 'text-white'}`}>
                <Markdown>{props.data.title}</Markdown>
              </h3>
              <p>{props.data.subtitle}</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default IconsWithRichTextAndImage
