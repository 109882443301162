import Markdown from 'markdown-to-jsx'
import { TitleAndRichTextModel } from 'models/body/TitleAndRichTextModel'
import { ThemeType } from 'models/enums/ThemeType'
import React, { Fragment, FunctionComponent } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'
import Fade from 'react-reveal/Fade'

type Props = {
  data: TitleAndRichTextModel
}

const TitleAndRichText: FunctionComponent<Props> = (props) => {
  return (
    <Fade>
      <section style={props.data.dynamicStyles} className={`intro-banner md:pt-[100px] -mt-[72px] pt-[50px] relative z-10 ${GetThemeClasses(props.data.theme)} ${props.data.styleClassNames}`}>
        <div className="container">
          <div className="intro-title md:text-2xl md:leading-10 text-box text-lg leading-10 font-lato border-solid pb-10">
            <h2
              className={`lg:text-[40px] md:text-[30px] font-lato font-bold text-[25px] leading-[1.25em] lg:w-2/3 mx-auto ${
                props.data.theme !== ThemeType.Dark && props.data.theme !== ThemeType.Accent && 'text-black'
              }`}
            >
              {props.data.title}
            </h2>
            <div className={`intro-content lg:w-2/3 mx-auto ${!props.data.styleClassNames && 'prose max-w-none' } prose-p:text-lg prose-h3:font-bold prose-h3:text-xl prose-h5:font-bold prose-h5:text-xl prose-p:m-0 prose-h2:mb-0 prose-h2:mt-10 prose-ul:mt-0 prose-ul:text-lg ${props.data.theme !== ThemeType.Dark && props.data.theme !== ThemeType.Accent && 'text-black'}`}>
              <Markdown>{props.data.content}</Markdown>
            </div>            
          </div>
        </div>
      </section>
    </Fade>
  )
}

export default TitleAndRichText
