import Button from 'components/core/Button'
import DownloadBlock from 'components/core/DownloadBlock'
import ResponsiveImage from 'components/core/ResponsiveImage'
import { DownloadsSectionModel } from 'models/body/DownloadsSectionModel'
import React, { Fragment, FunctionComponent } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'
import Fade from 'react-reveal/Fade'

type Props = {
  data: DownloadsSectionModel
}

const DownloadsSection: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <section id="download-app" style={props.data.dynamicStyles} className={`${GetThemeClasses(props.data.theme)} download-app py-12 md:pt-[150px] md:pb-28 mb-16`}>
        <div className="container">
          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 max-w-[922px] mx-auto">
            {props.data.downloadBlocks.map((block, index) => (
              <Fade left={index <= 1} right={index >= 2} key={index}>
                <DownloadBlock keyVal={index} data={block} />
              </Fade>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default DownloadsSection
