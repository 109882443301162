import ResponsiveImage from 'components/core/ResponsiveImage'
import { TextWithIconsModel } from 'models/body/TextWithIconsModel'
import { ThemeType } from 'models/enums/ThemeType'
import React, { Fragment, FunctionComponent } from 'react'
import { GetThemeClasses } from 'utils/GetThemeClasses'

type Props = {
  data: TextWithIconsModel
}

const TextWithIcons: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      <section
        style={props.data.dynamicStyles}
        className={`md:py-[190px] relative -mt-[130px] pt-[190px] pb-10 overflow-y-hidden ${GetThemeClasses(props.data.theme)} ${props.data.styleClassNames}`}
      >
        <div className="container">
          <h2 className={`md:text-3xl text-center text-[25px] leading-[1.3em] font-lato font-bold mb-4 ${props.data.theme === ThemeType.Light ? 'text-black' : 'text-white'}`}>
            {props.data.title}
          </h2>
          <div className="md:text-2xl text-xl leading-[1.3em] text-center lg:w-2/3 mx-auto">
            <p>{props.data.subtitle}</p>
          </div>

          <div className="md:py-[60px] flex flex-wrap pt-4 pb-0 max-w-[970px] mx-auto">
            {props.data.icons.map((icon, index) => {
              return (
                <div key={index} className="lg:w-1/5 md:w-1/2 p-4 w-full">
                  <div className="md:h-48 relative rounded text-center flex flex-col items-center">
                    <ResponsiveImage data={icon.image} />
                    <p className="text-white font-lato text-[14px] font-bold mt-5">{icon.title}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default TextWithIcons
